import { defineStore } from 'pinia';

export const useSubmissionsStore = defineStore({
  id: 'submissions',
  state: () => ({
    submissions: {},
  }),
  getters: {},
  actions: {
    getSubmission(exercise) {
      return this.submissions[exercise];
    },
    reset() {
      this.submissions = {};
    },
  },
});
