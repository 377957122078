<template>
  <div
    class="items-center flex gap-2 cursor-pointer"
    @click="
      router.push({
        name: 'course-dashboard',
        params: { course },
      })
    ">
    <ArrowRight class="ltr:rotate-180" />
    <div>{{ caption }}</div>
  </div>
</template>

<script setup lang="ts">
import { ArrowRight } from 'lucide-vue-next';
import { useRouter } from 'vue-router';

defineProps({
  course: {
    type: String,
    required: true,
  },
  caption: {
    type: String,
    required: true,
  },
});

const router = useRouter();
</script>
