import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const syncEnrollment = (enrollment, page) => {
  return useApi(
    http.post(`/enrollments/${enrollment}/sync`, {
      current_page: page,
    }),
  );
};

export const setTrack = (enrollment, lesson, block, track) => {
  return useApi(
    http.put(`/enrollments/${enrollment}/track`, {
      lesson,
      block,
      track,
    }),
  );
};
