<template>
  <div class="bg-white z-[1000] border-b border-b-[0.8px] h-10">
    <div
      class="px-6 h-full sticky top-0 flex justify-between items-center space-header">
      <slot name="prefix">
        <div class="flex gap-x-2 items-center">
          <AmitLogo />
          <div
            class="text-lg font-anomaliaBold cursor-pointer"
            tabindex="0"
            role="button"
            @keyup.enter="navigateToDashboard"
            @click="navigateToDashboard">
            {{ userSchool }}
          </div>
        </div>
      </slot>

      <div class="flex gap-x-5 items-center">
        <div
          v-if="route.name !== 'skill-lesson'"
          class="flex items-center gap-x-1">
          <img :src="amitCoin" class="w-5 h-5" alt="amitCoin" />
          <span class="text-secondary-900 text-xs font-anomaliaBold">
            {{ accountStore.user.coins }} אמיתקוין
          </span>
        </div>

        <BaseDropdown class="hidden">
          <template #trigger>
            <div class="flex gap-x-2 items-center relative">
              <Bell class="stroke-blue-sea-950 w-9 cursor-pointer" />
              <div
                v-if="notificationsStore.getUnreadNotifications.length"
                class="absolute flex justify-center items-center bottom-5 left-5 bg-primary h-5 w-5 rounded-full">
                <div
                  v-if="notificationsStore.getUnreadNotifications.length > 10"
                  class="text-white mt-0.5 font-simplerBold text-xxs tracking-tighter">
                  +10
                </div>
                <div v-else class="text-white mt-0.5 font-simplerBold text-xs">
                  {{ notificationsStore.getUnreadNotifications.length }}
                </div>
              </div>
            </div>
          </template>
          <NotificationWidget with-resize-icon class="left-0 absolute !w-60" />
        </BaseDropdown>

        <BaseDropdown>
          <template #trigger>
            <img
              v-if="accountStore.userAvatar"
              class="h-6 w-6 cursor-pointer"
              :src="accountStore.userAvatar"
              alt="avatar"/>
            <LetterAvatar
              v-else
              :user-name="accountStore.user.fullName || ''"/>
          </template>
          <ListGroup>
            <ListGroupItem
              tabindex="0"
              role="button"
              @keyup.enter="setAvatar()"
              @click="setAvatar()">
              <div class="text-center">החלפת אווטאר</div>
            </ListGroupItem>
            <ListGroupItem
              tabindex="0"
              role="button"
              @keyup.enter="logout"
              @click="logout">
              <div class="text-center">התנתקות</div>
            </ListGroupItem>
          </ListGroup>
        </BaseDropdown>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
//@ts-nocheck
import { computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAccountStore } from '/@/store/account';
import { useCourseStore } from '/@/store/course';
import { useAuth } from '@amit/auth';
import AmitLogo from '/@/assets/amitLogo.svg?component';
import Bell from '/@/assets/bell.svg?component';
import { get } from 'lodash';
import {
  BaseDropdown,
  ListGroup,
  ListGroupItem,
} from '@nwire/amit-design-system';
import amitCoin from '/@/assets/amitCoin.svg?url';
import emitter from '@amit/composables/src/useBus';
import { useNotificationStore } from '/@/store/notifications';
import NotificationWidget from '/@/components/NotificationWidget.vue';
import LetterAvatar from '@amit/layout/src/components/LetterAvatar.vue';

const { logout } = useAuth();
const accountStore = useAccountStore();
const courseStore = useCourseStore();
const notificationsStore = useNotificationStore();
const router = useRouter();
const route = useRoute();

const userSchool = computed(() => get(accountStore, 'user.school.name', ''));

const setAvatar = async () => {
  await router.push({ name: 'student-dashboard' });
  emitter.emit('openAvatarModal');
};

const navigateToDashboard = () => {
  router.push({ name: 'student-dashboard' });
};

watchEffect(() => {
  if (route.params.course) {
    courseStore.fetchCourse(route.params.course);
  }
});
</script>
