import { fetchSubmissions, submitAnswer } from '/@/services/students';
import { get } from 'lodash';
import { useAccountStore } from '/@/store/account';
import { syncEnrollment } from '/@/services/enrollments';
import { useSubmissionsStore } from '/@/store/submissions';
import { Submission } from '../types/interfaces';

const accountStore = useAccountStore();
const submissionStore = useSubmissionsStore();

export function useActivities() {
  const getSubmissions = async ({
    student = '',
    exercise = '',
    group = '',
    blockPopulate = false,
  }): Promise<Submission[] | null> => {
    return new Promise(resolve => {
      const { onSuccess, onError } = fetchSubmissions({
        student,
        exercise,
        group,
        blockPopulate,
      });
      onSuccess(response => {
        resolve(response);
      });
      onError(() => {
        resolve(null);
      });
    });
  };

  const getExerciseSubmission = async (
    exercise: any,
    refetch = false,
    blockPopulate = false,
  ) => {
    if (refetch || !submissionStore.submissions[exercise]) {
      const response = await getSubmissions({
        student: accountStore.user.id,
        exercise,
        blockPopulate,
      });

      submissionStore.submissions[exercise] = get(response, 'data[0]', {});
    }

    return submissionStore.submissions[exercise];
  };

  const getQuestionSubmission = async ({
    exercise,
    question,
    refetch = false,
    blockPopulate = false,
    submitByGroup = false,
    digHistory = false,
  }) => {
    const exerciseSubmission = await getExerciseSubmission(
      exercise,
      refetch,
      blockPopulate,
    );

    const records = JSON.parse(
      JSON.stringify(exerciseSubmission.records ?? []),
    );
    const history = exerciseSubmission.history;
    const lastHistoryVersion = history?.length
      ? history[history.length - 1]
      : {};
    const historyRecords = JSON.parse(
      JSON.stringify(lastHistoryVersion.records ?? []),
    );

    if (submitByGroup) {
      return records.find(
        (record: any) => record.userId === accountStore.user.id,
      );
    }

    const submissionRecord = records.find((record: any) => {
      const submissionQuestion =
        typeof record.question === 'string'
          ? record.question
          : record.question?._id;
      return submissionQuestion === question;
    });

    if (!submissionRecord && digHistory) {
      return historyRecords.find((record: any) => record.question === question);
    }
    return submissionRecord;
  };

  const recordSubmission = async (
    enrollment: string,
    state: any,
    question: any,
  ): Promise<{ feedback: any; exerciseFeedback: any; submission: any }> => {
    return new Promise(resolve => {
      const { onSuccess, onError } = submitAnswer({
        enrollment,
        block: question.id,
        answer: state,
      });
      onSuccess((response: any) => {
        const responseData = get(response, 'data', {});
        const { block = {}, exercise = {}, submission } = responseData;
        const data = {
          feedback: { completion: block.completion, message: block.feedback },
          exerciseFeedback: {
            completion: exercise.completion,
            message: exercise.feedback,
          },
          submission,
        };
        resolve(data);
      });

      onError(() =>
        resolve({
          feedback: null,
          exerciseFeedback: null,
          submission: null,
        }),
      );
    });
  };

  const syncEnrollmentPage = async (
    enrollment: any,
    page: any,
  ): Promise<{ instructions: string | null }> => {
    return new Promise(resolve => {
      const { onSuccess, onError } = syncEnrollment(enrollment, page);
      onSuccess((response: any) => {
        const { next_page } = response.data;
        resolve({
          instructions: next_page,
        });
      });

      onError(() =>
        resolve({
          instructions: null,
        }),
      );
    });
  };

  return {
    getExerciseSubmission,
    getQuestionSubmission,
    recordSubmission,
    syncEnrollmentPage,
    getSubmissions,
  };
}
