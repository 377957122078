import { ref, Ref } from 'vue';
import { get } from 'lodash';
import { useActivities } from '/@/composables/useActivities';
import { useStateMapper } from '/@/composables/useStateMapper';
import { fetchRules } from '/@/services/rules';
import { useCourseStore } from '/@/store/course';

const courseStore = useCourseStore();
const { getQuestionSubmission } = useActivities();
const { canContinue, toState, toContent } = useStateMapper();

const rules = {};

interface IPersonaState {
  [key: string]: string;
}
interface UseStateOptions {
  personaState?: Ref<IPersonaState>;
  tracks: Ref<string[]>;
}

export function useState({ personaState = ref({}), tracks }: UseStateOptions) {
  const getQuestionAnswer = submission => {
    return get(submission, 'answer', '');
  };

  const fetchPageState = async blocks => {
    const state = {};
    const questionBlocks = blocks.filter(block => block.type === 'question');
    const trackBlocks = blocks.filter(block => block.settings?.trackSelection);
    const regularPersonaSelectionBlocks = blocks.filter(
      block =>
        block.name === 'persona_selection' && !block.settings?.trackSelection,
    );

    for (let block of questionBlocks) {
      const questionSubmission = await getQuestionSubmission({
        exercise: block.exercise,
        question: block.id,
        submitByGroup: block.settings?.submitByGroup,
        digHistory: !block.settings?.from?.question,
      });
      state[block.id] = getQuestionAnswer(questionSubmission);
    }

    trackBlocks.forEach(block => {
      const optionsKey =
        block.name === 'persona_selection' ? 'personas' : 'options';
      const blockTracks: string[] =
        block.content?.[optionsKey]?.map(choice => choice.id) || [];
      const track = blockTracks.find(track => tracks.value.includes(track));
      if (track) {
        state[block.id] = block.name === 'persona_selection' ? track : [track];
      }
    });

    regularPersonaSelectionBlocks.forEach(block => {
      state[block.id] = personaState.value?.[block.content.type] || '';
    });

    return { state };
  };

  const getPageState = async (blocks, state, localState = {}) => {
    const pageState = state;
    const stateBlocks = blocks.filter(
      block =>
        block.type === 'question' ||
        block.settings?.trackSelection ||
        block.name === 'persona_selection',
    );

    const slideContent = blocks.filter(block => block.settings?.from);

    if (stateBlocks.length) {
      const from = slideContent.flatMap(content => {
        const {
          exercise = null,
          question = null,
          other = [],
        } = get(content, 'settings.from', {});
        const id = content.id;
        return [
          { exercise, question, id },
          ...other.map(otherItem => ({ ...otherItem, id })),
        ];
      });

      const promises = [
        fetchPageState(stateBlocks),
        ...from
          .map(
            item =>
              item.exercise &&
              getQuestionSubmission({
                exercise: item.exercise,
                question: item.question,
                refetch: true,
                blockPopulate: true,
              }),
          )
          .filter(Boolean),
      ];

      const [{ state: submissionAnswer }, ...previousSubmissions] =
        await Promise.all(promises);

      stateBlocks.forEach((block, index) => {
        const state = submissionAnswer[block.id] || localState?.[block.id];
        pageState[block.id] = toState(block, state);
        if (block.name == 'persona_selection') {
          const content = toContent({
            block,
            submissions: submissionAnswer[block.id],
          });
          for (const [key, value] of Object.entries(content)) {
            stateBlocks[index].content[key] = value;
          }
        }
      });

      previousSubmissions.forEach(previousSubmission => {
        const previousSubmissionAnswer = getQuestionAnswer(previousSubmission);
        const contentItem =
          from.find(
            item => item.question === previousSubmission?.question?._id,
          ) ?? {};
        const { type, id: contentId } = contentItem;
        const slideContentItem = slideContent.find(
          slide => slide.id === contentId,
        );

        if (slideContentItem) {
          const content = toContent({
            block: slideContentItem,
            submissions: previousSubmissionAnswer,
            previousBlock: previousSubmission.question,
            type,
          });
          Object.assign(slideContentItem.content, content);
        }
      });
    }

    return { state: pageState };
  };

  const getRules = async page => {
    return new Promise(resolve => {
      if (!rules[page]) {
        const { onSuccess } = fetchRules(page);
        onSuccess(({ data }) => {
          rules[page] = data;
          resolve(data);
        });
      } else {
        resolve(rules[page]);
      }
    });
  };

  const getExerciseDisabled = (pageDisabled, questionSet) => {
    return questionSet.some(group => {
      if (group.behaviour === 'any') {
        return group.questions.every(question => pageDisabled[question]);
      }
      return group.questions.some(question => pageDisabled[question]);
    });
  };

  const getPageIncludesAllQuestions = (blocks, questionSet) => {
    const isQuestionInBlock = question =>
      blocks.find(block => block.id === question);
    const areAllQuestionsInBlock = group =>
      group.questions.every(isQuestionInBlock);
    return questionSet.every(areAllQuestionsInBlock);
  };
  const getQuestionSet = blocks => {
    const exercise = blocks.find(block => block.exercise)?.exercise;
    if (!exercise) {
      return [];
    }
    const exerciseData = courseStore.getExercise(exercise);
    const questionSet: any = get(exerciseData, 'settings.question_set', []);
    const pageIncludesAllQuestions = getPageIncludesAllQuestions(
      blocks,
      questionSet,
    );
    if (!questionSet.length || !pageIncludesAllQuestions) {
      return [];
    }
    return questionSet;
  };

  const getDisabled = (blocks, state) => {
    const pageDisabled = {};
    let disabled = false;
    blocks.forEach(block => {
      const blockStateEmpty = !canContinue(block, state[block.id]);
      pageDisabled[block.id] = blockStateEmpty;
      const blockRequired = !(
        block.settings?.optional || block.settings?.display === 'hint'
      );
      const blockDisabled =
        block?.settings?.submissionStyle === 'inline'
          ? !block.state?.submitted
          : blockStateEmpty;
      disabled = disabled || (blockRequired && blockDisabled);
    });
    const questionSet = getQuestionSet(blocks);
    if (questionSet.length) {
      disabled = getExerciseDisabled(pageDisabled, questionSet);
    }
    return { disabled, pageDisabled, hasQuestionSet: questionSet.length };
  };

  return {
    getPageState,
    getRules,
    getDisabled,
    getQuestionSet,
  };
}
