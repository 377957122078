import { get } from 'lodash';
import { Submission } from '../types/interfaces';
import { generateObjectId } from '@/utils';

interface ContentParams {
  block: any;
  submissions: any;
  previousBlock?: any;
  type?: string;
}

export function useStateMapper() {
  const getChoiceEnabled = (state: any, block: any) => {
    const otherChoiceEmpty = state.some(
      (item: any) => typeof item === 'object' && !item.text,
    );
    return !otherChoiceEmpty && state.length >= (block.content.min || 1);
  };
  const customVideoEnabled = (_, block) => {
    return block.watched;
  };
  const getSmartGoalEnabled = (state, block) => {
    if (Object.keys(state).length === 0) {
      return false;
    }
    const choiceIds = block.content.items.flatMap(item =>
      item.choices.map(choice => choice.id),
    );
    return choiceIds.every(choiceId => !!state[choiceId]);
  };

  const getPersonaSelectionEnabled = (state, block) => {
    return !!state && !!block.watched;
  };

  const getFileUploadEnabled = (state, block) => {
    return state.length === block.content.options.length;
  };

  const getFreeTextEnabled = state => {
    const cleanedState = state.replace(/<[^>]*>?/gm, '');
    return cleanedState.length > 0;
  };

  const getCalendarEnabled = (state, block) => {
    const choicesLength = block.settings?.choices?.length || 1;
    return state?.events?.length >= choicesLength;
  };

  const getMatchDragEnabled = (state, block) => {
    if (Object.values(state).length === 0) {
      return false;
    }
    return block.content.matches.some(
      match => !!state[match.id] && state[match.id].length,
    );
  };

  const isEmptyTask = value => {
    return (
      (typeof value === 'string' || Array.isArray(value)) && value.length > 0
    );
  };

  const getProjectPlanEnabled = state => {
    const tasksLine = ['criteria', 'estimation', 'tasks', 'resources'];
    return state.every(obj =>
      tasksLine.every(property => isEmptyTask(obj[property])),
    );
  };

  const getPlanControlEnabled = (state, block) => {
    return block.content.options.every(option => {
      const goal = state.find(item => item.id === option.id);
      return (
        goal?.status &&
        (['done', 'on_track'].includes(goal.status) ||
          (goal.reason.length && getProjectPlanEnabled([goal])))
      );
    });
  };

  const getGanttEnabled = (state, block) => {
    if (!block.content.droppable) {
      return true;
    }
    return state.tasks.length > 0;
  };

  const getTextAreaEnabled = (state, block) => {
    return state.filter(item => !!item.text).length >= (block.content.min || 1);
  };

  const getText = answer => {
    return typeof answer === 'string' ? answer : answer?.text;
  };

  const getProjectPlanContent = ({ submissions }) => {
    if (!submissions?.length) {
      return {};
    }
    let typedSubmissions =
      typeof submissions === 'string' ? [submissions] : submissions;
    return {
      options: typedSubmissions.map((submission, index) => ({
        id: `${index + 1}`,
        title: getText(submission),
      })),
    };
  };

  const getDraggableTableEnabled = (state, block) => {
    return !block.content.items.some((blockItem, index) => {
      return block.content.headers.some(header => {
        const contentArray = blockItem[header.key].content;
        const hasDroppableContents = contentArray.some(item => item.droppable);
        return hasDroppableContents && !state?.[index]?.[header.key]?.length;
      });
    });
  };

  const getPlanControlContent = ({ submissions }) => {
    return submissions?.length ? { options: submissions } : {};
  };

  const getGanttContent = ({ submissions }) => {
    const tasks = submissions.flatMap(submission => submission.tasks);
    return { tasks };
  };

  const getCalendarDragContent = ({ submissions }) => {
    const options = submissions.flatMap(submission => submission.tasks);
    return { options };
  };

  const getTableContent = ({ submissions, block }) => {
    if (!submissions.length) {
      return {};
    }
    const items = submissions.map(submission => {
      return block.content.headers.reduce((item, header) => {
        const propertyKey = header.key;
        const propertyValue = submission[propertyKey];
        const splitText = Array.isArray(propertyValue)
          ? propertyValue
          : propertyValue.split('\n');
        return {
          ...item,
          [propertyKey]: {
            content: splitText.map(text =>
              text.title ? { text: text.title } : { text },
            ),
          },
        };
      }, {});
    });
    return { items };
  };

  const getChartContent = ({ submissions }) => {
    if (!submissions.length) {
      return {};
    }
    const data = {
      labels: submissions.map(({ text }) => text),
      datasets: [{ data: submissions.map(({ duration }) => duration / 60) }],
    };
    return { data };
  };

  const getPriorityQuadrantEnabled = (state, block) => {
    const count = state.reduce((rowAcc, row) => {
      const rowLengths = block.content.headers.reduce((acc, header) => {
        return acc + row[header.key].length;
      }, 0);
      return rowAcc + rowLengths;
    }, 0);
    return count === block.content.options.length;
  };

  const getTimeEstimationEnabled = (state, block) => {
    const totalDuration = state.reduce((sum, task) => sum + task.duration, 0);
    const hasInvalidTasks = state.some(
      task => task.duration === 0 || task.text.trim() === '',
    );
    return totalDuration / 60 === block.content.totalHours && !hasInvalidTasks;
  };

  const getPersonaSelectionContent = ({ submissions }) => {
    return { selectable: !submissions };
  };
  const pairingBlockEnabled = (state, block) => {
    return block.content.matches.length === Object.values(state).length;
  };
  const audioPlayerEnabled = state => {
    return !!state?.file;
  };

  const audioListenerEnabled = (state, block) => {
    return block.watched;
  };

  const fillBlanksEnabled = state => {
    return Object.values(state).every((item: any) => item.length && !!item[0]);
  };

  const getPriorityQuadrantContent = ({ submissions }) => {
    if (!submissions.length) {
      return {};
    }
    return {
      options: submissions.map((submission, index) => ({
        id: `${index + 1}`,
        title: submission.text,
      })),
    };
  };

  const getEvaluationTableContent = ({
    submissions,
    type = 'items',
    previousBlock,
  }) => {
    if (type === 'headers') {
      return {
        headers: [
          { key: 'title', title: '' },
          ...submissions.map(submission => ({
            key: submission.id,
            title: submission.text,
          })),
        ],
      };
    }
    let items = submissions;
    if (previousBlock?.name === 'textarea') {
      items = submissions.map(submission => ({
        title: {
          id: submission.id,
          content: [{ text: submission.text, id: 1 }],
        },
      }));
    }

    return { items };
  };

  const getWeightingTableContent = ({
    submissions,
    type = 'items',
    previousBlock,
    block,
  }) => {
    if (type === 'headers') {
      return {
        headers: [
          { key: 'title', title: '', type: 'decision_rank' },
          ...submissions.map(submission => ({
            key: submission.id,
            title: submission.text,
            type: 'decision_score',
          })),
        ],
      };
    }
    let items = submissions;
    if (previousBlock?.name === 'advanced_table') {
      items = submissions.map(submission => {
        const newItem = {
          title: {
            id: submission.id,
            content: [
              {
                text: submission.consideration.content[0].text,
                value: submission.weight.content[0].value,
                id: 1,
              },
            ],
          },
        };
        block.content.headers.forEach((header, headerIndex) => {
          newItem[header.key] = newItem[header.key] || {
            id: headerIndex,
            content: [{ id: generateObjectId(), text: '', value: 0 }],
          };
        });
        return newItem;
      });
    }
    return { items };
  };
  const getTableEnabled = (state, block) =>
    state.every(item =>
      block.content.headers.every((header: any) =>
        item[header.key].content.every(
          contentItem => contentItem.text.trim() !== '',
        ),
      ),
    );

  const getEvaluationTableEnabled = (state, block) => {
    if (!block.content.tickable) {
      return getTableEnabled(state, block);
    }
    return state.every(item =>
      block.content.headers.some((header: any) =>
        item[header.key].content.some(contentItem => contentItem.checked),
      ),
    );
  };

  const getDynamicTableEnabled = (state, block) =>
    state.every(
      item => item.text.trim() !== '' && getTableEnabled(item.content, block),
    );
  const getAdvancedTableContent = ({ submissions, block }) => {
    let items = submissions.map(option => {
      let content = { id: option.id };
      block.content.headers.forEach(header => {
        content[header.key] = {
          id: generateObjectId(),
          content:
            header.type === 'select'
              ? [{ id: generateObjectId(), text: '', value: 0 }]
              : [{ id: generateObjectId(), text: option.text }],
        };
      });
      return content;
    });
    return { items: items.length ? items : [] };
  };

  const getDynamicTableContent = ({ submissions, block }) => {
    let items = submissions.map(option => {
      let content = option.content || [{ id: generateObjectId() }];
      content = content.map(item => {
        const newItem = { ...item };
        block.content.headers.forEach(header => {
          newItem[header.key] = newItem[header.key] || {
            id: generateObjectId(),
            content: [{ id: generateObjectId(), text: '' }],
          };
        });
        return newItem;
      });
      return {
        id: option.id,
        text: option.text,
        content,
      };
    });
    return { items: submissions.length ? items : [] };
  };

  const highlightEnabled = state => {
    const hasMark = /<mark\b[^>]*>/i.test(state);
    return hasMark;
  };

  const wordCloudEnabled = (state, block) => {
    return state?.length >= block?.content?.minAnswers;
  };

  const pollEnabled = state => {
    return !!state?.length;
  };

  const getDisclaimerContent = ({ submissions }) => {
    return submissions?.length
      ? { text: `להזכירך המטרה שלך היא: ${submissions[0]?.text}` }
      : {};
  };

  const getChoiceContent = ({ submissions, previousBlock }) => {
    if (!submissions.length) return { options: [] };

    const isTextarea = previousBlock.name === 'textarea';
    const options = isTextarea
      ? submissions.map(({ text, id }) => ({ title: text, id }))
      : previousBlock.content.options.filter(({ id }) =>
          submissions.includes(id),
        );

    return { options };
  };

  const getRichTextContent = ({ submissions, previousBlock }) => {
    const isTextarea = previousBlock.name === 'textarea';
    if (isTextarea) {
      const data = {
        type: 'doc',
        content: submissions.map(submission => ({
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: submission.text,
            },
          ],
        })),
      };
      return { data };
    }
    return { data: submissions };
  };

  const getTextAreaContent = ({ submissions, previousBlock }) => {
    const isTextarea = previousBlock.name === 'textarea';
    if (isTextarea) {
      return {
        options: submissions.map(({ text, id }) => ({ title: '', id, text })),
      };
    }

    return { items: submissions };
  };

  const getPlanCheckEnabled = (state, block) => {
    return block.content.options.every(option => {
      const goal = state.find(item => item.id === option.id);
      return !!goal?.status;
    });
  };

  const getPlanCheckContent = ({ submissions }) => {
    return submissions?.length ? { options: submissions } : {};
  };

  const getSortEnabled = state => {
    if (!state.length) return false;
    return true;
  };

  const handlers = {
    choice: {
      initialValue: submissions => submissions || [],
      content: getChoiceContent,
      enabled: getChoiceEnabled,
    },
    smart_goal: {
      initialValue: submissions => submissions || {},
      enabled: getSmartGoalEnabled,
    },
    match: {
      initialValue: submissions => submissions || {},
      enabled: getMatchDragEnabled,
    },
    persona_selection: {
      initialValue: submissions => submissions || '',
      content: getPersonaSelectionContent,
      enabled: getPersonaSelectionEnabled,
    },
    free_text: {
      initialValue: submissions => submissions || '',
      enabled: getFreeTextEnabled,
    },
    rich_text: {
      initialValue: submissions => submissions || '',
      content: getRichTextContent,
    },
    project_plan: {
      initialValue: submissions => submissions || [],
      enabled: getProjectPlanEnabled,
      content: getProjectPlanContent,
    },
    plan_control: {
      initialValue: submissions => submissions || [],
      enabled: getPlanControlEnabled,
      content: getPlanControlContent,
    },
    plan_check: {
      initialValue: submissions => submissions || [],
      enabled: getPlanCheckEnabled,
      content: getPlanCheckContent,
    },
    calendar_drag: {
      initialValue: submissions => submissions || { events: [] },
      enabled: getCalendarEnabled,
      content: getCalendarDragContent,
    },
    file_upload: {
      initialValue: submissions => submissions || [],
      enabled: getFileUploadEnabled,
    },
    draggable_table: {
      initialValue: submissions => submissions || [],
      enabled: getDraggableTableEnabled,
    },
    gantt: {
      initialValue: submissions => submissions || { tasks: [] },
      content: getGanttContent,
      enabled: getGanttEnabled,
    },
    sort: {
      initialValue: submissions => submissions || [],
      enabled: getSortEnabled,
    },
    textarea: {
      initialValue: submissions => submissions || [],
      enabled: getTextAreaEnabled,
      content: getTextAreaContent,
    },
    custom_video: {
      enabled: customVideoEnabled,
    },
    priority_quadrant: {
      initialValue: submissions => submissions || [],
      content: getPriorityQuadrantContent,
      enabled: getPriorityQuadrantEnabled,
    },
    pairing: {
      initialValue: submissions => submissions || {},
      enabled: pairingBlockEnabled,
    },
    audio_player: {
      initialValue: submissions => submissions || { file: '' },
      enabled: audioPlayerEnabled,
    },
    fill_blanks: {
      initialValue: submissions => submissions || {},
      enabled: fillBlanksEnabled,
    },
    time_estimation: {
      initialValue: submissions => submissions || [],
      enabled: getTimeEstimationEnabled,
    },
    chart: {
      content: getChartContent,
    },
    table: {
      content: getTableContent,
    },
    audio_listener: {
      initialValue: submissions => submissions || { src: '' },
      enabled: audioListenerEnabled,
    },
    highlight: {
      initialValue: submissions => submissions || '',
      enabled: highlightEnabled,
    },
    word_cloud: {
      initialValue: submissions => submissions || [],
      enabled: wordCloudEnabled,
    },
    poll: {
      initialValue: submissions => submissions || [],
      enabled: pollEnabled,
    },
    disclaimer: {
      content: getDisclaimerContent,
    },
    dynamic_table: {
      initialValue: submissions => submissions || [],
      content: getDynamicTableContent,
      enabled: getDynamicTableEnabled,
    },
    evaluation_table: {
      initialValue: submissions => submissions || [],
      content: getEvaluationTableContent,
      enabled: getEvaluationTableEnabled,
    },
    weighting_table: {
      initialValue: submissions => submissions || [],
      content: getWeightingTableContent,
      enabled: getTableEnabled,
    },
    advanced_table: {
      initialValue: submissions => submissions || [],
      content: getAdvancedTableContent,
      enabled: getTableEnabled,
    },
  };

  const getHandler = (name, action, fallback: any) => {
    return get(handlers, `${name}.${action}`, fallback);
  };

  const canContinue = (block, state) => {
    const handler: any = getHandler(block.name, 'enabled', null);
    if (!handler) {
      return true;
    }
    return handler(state, block);
  };
  const toState = (block, submissions) => {
    const handler: any = getHandler(block.name, 'initialValue', () => []);
    return handler(submissions, block);
  };

  const toContent = ({
    block,
    submissions,
    previousBlock,
    type,
  }: ContentParams) => {
    const handler: any = getHandler(block.name, 'content', () => ({}));
    return handler({ submissions, block, previousBlock, type });
  };

  const mapWordCloudSubmission = (submission: Submission, userId: string) => {
    const wordCloudData = {
      words: [] as { text: string; count: number }[],
      state: [] as string[],
      blockId: '',
    };
    const userSubmissionIndex = submission.records?.findIndex(
      record => record.userId == userId,
    );
    if (userSubmissionIndex !== -1) {
      wordCloudData.state = submission.records[userSubmissionIndex].answer;
    }

    const answersArray = submission.records.flatMap(record => record.answer);
    const counts = new Map();
    answersArray.forEach(text => {
      counts.set(text, (counts.get(text) || 0) + 1);
    });
    wordCloudData.words = Array.from(counts, ([text, count]) => ({
      text,
      count,
    }));
    wordCloudData.blockId = submission.records[0].question;
    return wordCloudData;
  };

  const mapPollSubmission = (submission: Submission, userId: string) => {
    const pollData = {
      answers: [] as {
        title: String;
        id: String;
        count: Number;
        currentUserVote: Boolean;
      }[],
      state: [] as string[],
      blockId: '',
    };

    const answerCounts = submission.records.reduce((acc, record) => {
      if (!record.answer) {
        return acc;
      }
      const answer = record.answer[0];
      if (!acc[answer]) {
        acc[answer] = { count: 0, userVote: false };
      }
      acc[answer].count += 1;
      if (record.userId === userId) {
        acc[answer].userVote = true;
      }
      return acc;
    }, {});
    const result = Object.keys(answerCounts).map(answer => ({
      title: '',
      id: answer,
      count: answerCounts[answer].count,
      currentUserVote: answerCounts[answer].userVote,
    }));

    pollData.answers = result;

    const userSubmissionIndex = submission.records?.findIndex(
      record => record.userId == userId,
    );
    if (userSubmissionIndex !== -1) {
      pollData.state = submission.records[userSubmissionIndex].answer;
    } else {
      pollData.state = [];
    }
    return pollData;
  };

  return {
    canContinue,
    toState,
    toContent,
    mapWordCloudSubmission,
    mapPollSubmission,
  };
}
